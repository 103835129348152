<template>
  <div style="position: relative;" v-if="landing.branding.emailList" :style="`height:${landing.branding.emailList.preview?landing.branding.emailList.height+'px':'auto'};`">
    <div class="canvas-footer" style="padding:10px;justify-content:center;align-items:center;margin:0 auto;min-height: 100px;" :style="`position:relative;z-index:2;margin-bottom:${landing.branding.bottomMenu.show?'60px':'0'};overflow:hidden;border-radius:${landing.branding.emailList.radius}px;width:${landing.branding.emailList.width}%;height:${landing.branding.emailList.height}px;background-color:${landing.branding.emailList.bg_color};background:${landing.branding.emailList.preview?'transparent':'url('+landing.branding.emailList.bg+') center/cover no-repeat'};`" v-if="page === 'home'&& landing.branding.emailList.show || page==='reviews' &&  landing.branding.emailList.show  &&!landing.free||  page === 'advanced' && landing.advanced.tab === 2 &&  landing.branding.emailList.show ||page === 'home'&&  landing.branding.emailList.show || page==='reviews' &&  landing.branding.emailList.show  && !landing.free  ||  page === 'advanced' && landing.advanced.tab === 2 &&  landing.branding.emailList.show ">
      <div  v-if="landing.branding.emailList.top_text[landing.current_lang]" style="min-height: auto;height:auto;" v-html="htmlData(landing.branding.emailList.top_text[landing.current_lang])"></div>
      <div style="display:flex;">
      <v-text-field dense outlined hide-details placeholder="Email" solo>

      </v-text-field>
      <v-btn small :style="`color:${landing.branding.emailList.btn_color};background-color:${landing.branding.emailList.btn_bg}`">
        {{ landing.branding.emailList.btn_text[landing.current_lang] }}
      </v-btn>
      </div>
      <div  v-if="landing.branding.emailList.bottom_text[landing.current_lang]" style="min-height: auto;height: auto;" v-html="htmlData(landing.branding.emailList.bottom_text[landing.current_lang])"></div>
    </div>
    <div class="canvas-emailList_preview" style="margin:0 auto;min-height: 100px;" :style="`left:${(343-(343*(+landing.branding.emailList.width/100)))/2}px;position:absolute;top:0;margin-bottom:${landing.branding.bottomMenu.show?'60px':'0'};overflow:hidden;border-radius:${landing.branding.emailList.radius}px;width:${landing.branding.emailList.width}%;height:${landing.branding.emailList.height}px;`" v-if="landing.branding.emailList.preview">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment";
export default {
  name:"EmailListPreview",
  props:['page'],
  data(){
    return{
      datenow:''
    }
  },
  computed:{
    ...mapState(['landing']),
    dateToday: function () {
      return moment().format("DD.MM.YYYY");
    },
  },
  mounted() {
    this.timeNow();
  },
  methods:{
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data){
      if(data){
        const weatherInd = data.split(' ').indexOf(':weather:');
        let result = data.split(' ');
        if(weatherInd && this.landing.advanced.global.show_weather){
          const newArr = result;
          newArr[weatherInd] = this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(0)+' ℃'
          result = newArr;
        }
        const timeInd = data.split(' ').indexOf(':time:');
        if(timeInd){
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(' ').indexOf(':date:');
        if(dateInd){
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        return result.join(' ');
      }
    },
  }
}
</script>

<style>
</style>